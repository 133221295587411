<template>
  <v-dialog v-model="dialog" :max-width="options.maxWidth">
    <v-card>
      <v-card-title :class="options.titleColor">{{
        options.titleText
      }}</v-card-title>
      <v-card-text class="app-card-text" v-html="options.message">
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          @click="confirmed"
          :color="options.confirmButtonColor"
          :width="options.confirmButtonWidth"
          >{{ options.confirmButtonCaption }}</v-btn
        >
        <v-btn
          @click="cancelled"
          :color="options.cancelButtonColor"
          :width="options.cancelButtonWidth"
          >{{ options.cancelButtonCaption }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      options: {
        titleText: "Information",
        titleColor: "primary white--text",
        message: "",
        confirmButtonCaption: "OK",
        cancelButtonCaption: "Cancel",
        confirmButtonColor: "primary",
        cancelButtonColor: "primary",
        confirmButtonWidth: "120",
        cancelButtonWidth: "120",
        showConfirmButtonOnly: false,
        maxWidth: "500",
      },
    };
  },
  methods: {
    show(options) {
      const { message } = options;
      if (!message) return;
      this.dialog = true;
      Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirmed() {
      this.resolve(true);
      this.dialog = false;
    },
    cancelled() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.app-card-text {
  padding-top: 10px !important;
  font-size: 18px;
  min-height: 90px;
}
</style>
